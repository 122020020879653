import {
  RewardListPageKey,
  RewardSellingModalKey,
  StatusTextKey,
  VoucherCodeSetupPageKey
} from '../../TModules';

export const translations = {
  [RewardListPageKey]: {
    Reward: 'Prämie',
    List: 'Liste',
    All: 'Alle',
    Draft: 'Entwurf',
    Pending: 'Ausstehend',
    Scheduled: 'Geplant',
    Active: 'Aktiv',
    Ended: 'Beendet',
    Inactive: 'Inaktiv',
    TransactionIssued: 'Transaktion ausgestellt',
    Private: 'Privat',
    System: 'System',
    SellingFast: 'Schnell ausverkauft',
    SoldOut: 'Ausverkauft',
    Subtitle: 'Untertitel',
    Stackable: 'Stapelbar',

    Name: 'Name',
    Merchant: 'Händler',
    Status: 'Status',
    Balance: 'Guthaben',
    Redeemed: 'Eingelöst',
    Voided: 'Storniert',
    SellingFrom: 'Verkauf ab',
    SellingTo: 'Verkauf bis',
    Actions: 'Aktionen',
    RequestType: 'Anforderungstyp',
    Comments: 'Kommentare',
    LastUpdated: 'Zuletzt aktualisiert',

    CreateNew: 'Neu erstellen',
    Edit: 'Bearbeiten',
    DownloadCodes: 'Codes herunterladen',
    DuplicateReward: 'Prämie duplizieren',
    Deactivate: 'Deaktivieren',
    DownloadDepletedCodes: 'Erschöpfte Codes herunterladen',
    UnassginedVouchers: 'Nicht zugewiesene Gutscheine',
    ReplinishVouchers: 'Gutscheine auffüllen',
    Deplete: 'Erschöpfen',
    Replinish: 'Auffüllen',
    AppendBudget: 'Budget anhängen',
    Approve: 'Genehmigen',

    Type: 'Typ',
    RewardName: 'Prämienname',
    Description: 'Beschreibung',
    StepsToRedeem: 'Schritte zum Einlösen',
    SellingPeriod: 'Verkaufszeitraum',
    ActiveAt: 'Aktiv um',
    TermsAndConditions: 'Allgemeine Geschäftsbedingungen',
    RiskDisclaimer: 'Risikohinweis',
    BrandName: 'Markenname',
    Brands: 'Marken',
    Tags: 'Tags',
    Categories: 'Kategorien',
    Labels: 'Labels',
    Catalogues: 'Kataloge',
    UploadedPhotos: 'Hochgeladene Fotos',
    UploadedPhoto: 'Hochgeladenes Foto',
    UploadMultipleImages: 'Mehrere Bilder hochladen',
    UploadAFile: 'Datei hochladen',
    WhereCanThisbeRewardDisplayed: 'Wo kann diese Prämie angezeigt werden?',
    RewardCost: 'Prämienkosten',
    SellingPrice: 'Verkaufspreis',
    CustomFields: 'Benutzerdefinierte Felder',

    AudienceInfo: 'Zielgruppeninformationen',
    AudienceAttributes: 'Zielgruppenattribute',
    AudienceList: 'Zielgruppenliste',
    NoFiltersSelected: 'Keine Filter ausgewählt',
    NoListSelected: 'Keine Liste ausgewählt',

    RewardsMechanics: 'Prämienmechanik',
    CodeType: 'Code-Typ',
    Code: 'Code',
    VoucherCodeType: 'Gutscheincode-Typ',
    RewardIssuanceLimit: 'Prämienausgabelimit',
    TotalRedemptionLimit: 'Gesamteinlöselimit',
    RedemptionLimitsPerPeriod: 'Einlöselimits pro Zeitraum',
    RewardIssuancePerPeriodLimit: 'Prämienausgabelimit pro Zeitraum',
    UserLimits: 'Benutzerlimits',
    UserLimitsPerPeriod: 'Benutzerlimits pro Zeitraum',
    VoucherValidity: 'Gültigkeit des Gutscheins',
    RedemptionType: 'Einlöseart',
    GiftingOption: 'Geschenkoption',

    Public: 'Öffentlich',
    MainBodyText: 'Haupttext',
    AddAccordion: 'Akkordeon hinzufügen',

    Next: 'Weiter',
    Cancel: 'Abbrechen',
    Browse: 'Durchsuchen',

    ImportReward: 'Prämie importieren',
    StartDate: 'Startdatum',
    EndDate: 'Enddatum',
    OperatingDays: 'Betriebstage',
    From: 'Von',
    DiscountStartDate: 'Rabatt-Startdatum',
    DiscountEndDate: 'Rabatt-Enddatum',
    ID: 'ID',
    CashValue: 'Barwert',

    PointsValue: 'Punktewert',
    LoyaltyProgram: 'Treueprogramm',
    Identifier: 'Identifikator',
    Action: 'Aktion',
    ThisRewardsIsCurrentlyFREE: 'Diese Prämie ist derzeit KOSTENLOS',
    ReActivateSellingPrice:
      'Aktivieren Sie einen Verkaufspreis unten erneut oder fügen Sie einen neuen hinzu',
    AddSellingPrice: 'Verkaufspreis hinzufügen',
    EditSellingPrice: 'Verkaufspreis bearbeiten',
    HowDoYouValueYourRewards: 'Wie bewerten Sie Ihre Prämien?',
    CostPrice: 'Kostenpreis',
    SocialMedia: 'Soziale Medien',
    Title: 'Titel',
    Image: 'Bild',
    iOSAppLink: 'iOS-App-Link',
    AndroidAppLink: 'Android-App-Link',
    WebLink: 'Weblink',
    AddCustomField: 'Benutzerdefiniertes Feld hinzufügen',
    CustomField: 'Benutzerdefiniertes Feld',
    TypeInFieldName: 'Feldnamen eingeben',
    TypeInFieldValue: 'Feldwert eingeben',
    SelectCodeType: 'Code-Typ auswählen',
    TypeOfCode: 'Code-Typ',
    QRCode: 'QR-Code',
    BarCode: 'Barcode',
    URL: 'URL',
    SelectFile: 'Datei auswählen',
    ChooseFile: 'Datei auswählen',
    DownloadSample: 'Beispiel herunterladen (Akzeptiertes Format: nur .xlsx/.csv-Dateien)',
    Limits: 'Limits',
    IssueAMaximumOf: 'Maximal ausgeben',
    RewardsPer: 'Prämien pro',
    Second: 'Sekunde',
    Minutes: 'Minuten',
    Hours: 'Stunden',
    Days: 'Tage',
    Weeks: 'Wochen',
    Months: 'Monate',
    Year: 'Jahr',
    DisplayEvenWhenIssuanceLimitIsReached: 'Auch anzeigen, wenn das Ausgabelimit erreicht ist',
    RewardRedemptionLimit: 'Prämieneinlöselimit',
    LimitRedemptionsTo: 'Einlösungen begrenzen auf',
    UserLimit: 'Benutzerlimit',
    UserLimitPerPeriod: 'Benutzerlimit pro Zeitraum',
    DisplayEvenWhenLimitIsReached: 'Auch anzeigen, wenn das Limit erreicht ist',
    ValidityPeriod: 'Gültigkeitsdauer',
    SelectType: 'Typ auswählen',
    Period: 'ZEITRAUM',
    IssuanceDate: 'AUSGABEDATUM',
    EndOfMonth: 'MONATSENDE',
    RedemptionOutcomes: 'Einlöseergebnisse',
    AddOutcome: 'Ergebnis hinzufügen',
    Online: 'ONLINE',
    Offline: 'OFFLINE',
    MerchantPIN: 'HÄNDLER-PIN',
    Pin: 'PIN',
    APIRedemption: 'API-EINLÖSUNG',
    SetCountdown: 'Countdown einstellen',
    SelectGiftingOption: 'Geschenkoption auswählen',
    ScheduleLaunch: 'Start planen',
    MapYourRewardTo: 'Ordnen Sie Ihre Prämie zu',
    Include: 'Einschließen',
    SneakPeek: 'Vorschau',
    SelectLoyaltyProgram: 'Wählen Sie Ihr Treueprogramm',
    PickAudienceList: 'Zielgruppenliste auswählen',
    UploadNewList: 'Neue Liste hochladen',
    AcceptedFormat: 'Akzeptiertes Format: nur .xlsx/.csv-Dateien',
    AudienceNotInList: 'Zielgruppe nicht in der Liste?',
    PleaseSelectAMerchant: 'Bitte wählen Sie einen Händler',
    StartDateEndDateRequired: 'Start- und Enddatum erforderlich',
    EndDateCannotBeBeforeStartDate: 'Enddatum kann nicht vor dem Startdatum liegen',

    ValidityAtLeastOneHour: 'Gültigkeit muss mindestens 1 Stunde betragen',
    ValidFor: 'Gültig für',
    GraceTime: 'Karenzzeit',
    GraceTimeCannotBeNegative: 'Karenzzeit kann nicht negativ sein',
    Yes: 'Ja',
    No: 'Nein',

    RewardInfo: 'Prämieninformationen',
    RewardMechanics: 'Prämienmechanik',
    ActivationDate: 'Aktivierungsdatum',

    RewardMustHaveAName: 'Prämie muss einen Namen haben',
    AllowedFileTypes: 'Erlaubt: .jpg, .png, .svg',
    EitherAniOSOrAndroidOrWebLinkIsRequired:
      'Entweder ein iOS- oder Android- oder Web-Link ist erforderlich',
    Delete: 'Löschen',
    InsertPhoto: 'Bild einfügen',
    SelectImageType: 'Wahlen Sie png, svg, jpeg, oder gif-Bilder',
    Info: 'Info',
    Audience: 'Zielgruppe',
    SelectAudience: 'Zielgruppe auswählen',
    Review: 'Überprüfen',
    Mechanics: 'Mechanik',
    RewardDetails: 'Prämien-Details',
    SelectDate: 'Datum auswählen',
    SelectTime: 'Zeit auswählen'
  },
  [VoucherCodeSetupPageKey]: {
    NoCode: 'Kein Code',
    Single: 'Einzeln',
    SystemGenerated: 'Systemgeneriert',
    UserUpload: 'Benutzer-Upload',
    Inventory: 'Inventar',
    Stash: 'Vorrat',
    VoucherCodeSetup: 'Gutscheincode-Einrichtung',
    SelectCodeType: 'Code-Typ auswählen'
  },
  [RewardSellingModalKey]: {
    SelectLoyaltyProgram: 'Wählen Sie Ihr Treueprogramm',
    SelectTier: 'Wählen Sie Tier',
    Cash: 'Geld',
    PointsRegularPrice: 'Punkte (normaler Preis)',
    PointsDiscountPrice: 'Punkte (rabattierter Preis)',
    egHundred: 'z.B. 100',
    Optional: 'Optional',
    SelectGLAccount: 'Wählen Sie GL-Konto',
    GLAccountType: 'GL-Kontotyp',
    GLAccountName: 'GL-Kontoname'
  },
  [StatusTextKey]: {
    active: 'Aktiv',
    inactive: 'Inaktiv',
    draft: 'Entwurf',
    processing: 'Verarbeitung',
    pending: 'Ausstehend',
    approved: 'Genehmigt',
    scheduled: 'Programmiert',
    redeemed: 'Eingelöst',
    ended: 'Beendet',
    failed: 'Fehlgeschlagen',
    fail: 'Fehlschlagen',
    expired: 'Abgelaufen',
    public: 'Öffentlich'
  }
};
