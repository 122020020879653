import {
  RewardListPageKey,
  RewardSellingModalKey,
  StatusTextKey,
  VoucherCodeSetupPageKey
} from '../../TModules';

export const translations = {
  [RewardListPageKey]: {
    Reward: 'Reward',
    List: 'List',
    All: 'All',
    Draft: 'Draft',
    Pending: 'Pending',
    Scheduled: 'Scheduled',
    Active: 'Active',
    Ended: 'Ended',
    Inactive: 'Inactive',
    TransactionIssued: 'Transaction issued',
    Private: 'Private',
    System: 'System',
    SellingFast: 'Selling fast',
    SoldOut: 'Sold out',
    Subtitle: 'Subtitle',
    Stackable: 'Stackable',

    Name: 'Name',
    Merchant: 'Merchant',
    Status: 'Status',
    Balance: 'Balance',
    Redeemed: 'Redeemed',
    Voided: 'Voided',
    SellingFrom: 'Selling From',
    SellingTo: 'Selling To',
    Actions: 'Actions',
    RequestType: 'Request Type',
    Comments: 'Comments',
    LastUpdated: 'Last Updated',

    CreateNew: 'Create new',
    Edit: 'Edit',
    DownloadCodes: 'Download codes',
    DuplicateReward: 'Duplicate Reward',
    Deactivate: 'Deactivate',
    DownloadDepletedCodes: 'Download Depleted Codes',
    UnassginedVouchers: 'Unassigned Vouchers',
    ReplinishVouchers: 'Replenish Vouchers',
    Deplete: 'Deplete',
    Replinish: 'Replenish',
    AppendBudget: 'Append Budget',
    Approve: 'Approve',

    Type: 'Type',
    RewardName: 'Reward Name',
    Description: 'Description',
    StepsToRedeem: 'Steps to redeem',
    SellingPeriod: 'Selling Period',
    ActiveAt: 'Active At',
    TermsAndConditions: 'Terms & Conditions',
    RiskDisclaimer: 'Risk Disclaimer',
    BrandName: 'Brand name',
    Brands: 'Brands',
    Tags: 'Tags',
    Categories: 'Categories',
    Labels: 'Labels',
    Catalogues: 'Catalogues',
    UploadedPhotos: 'Uploaded photos',
    UploadedPhoto: 'Uploaded photo',
    UploadMultipleImages: 'Upload multiple images',
    UploadAFile: 'Upload a file',
    WhereCanThisbeRewardDisplayed: 'Where can this reward be displayed',
    RewardCost: 'Reward cost',
    SellingPrice: 'Selling Price',
    CustomFields: 'Custom fields',

    AudienceInfo: 'Audience Info',
    AudienceAttributes: 'Audience attributes',
    AudienceList: 'Audience List',
    NoFiltersSelected: 'No filters selected',
    NoListSelected: 'No list selected',

    RewardsMechanics: 'Rewards Mechanics',
    CodeType: 'Code Type',
    Code: 'Code',
    VoucherCodeType: 'Voucher Code Type',
    RewardIssuanceLimit: 'Reward Issuance Limit',
    TotalRedemptionLimit: 'Total Redemption Limit',
    RedemptionLimitsPerPeriod: 'Redemption Limits Per Period',
    RewardIssuancePerPeriodLimit: 'Reward Issuance Per Period Limit',
    UserLimits: 'User Limits',
    UserLimitsPerPeriod: 'User Limits per period',
    VoucherValidity: 'Voucher Validity',
    RedemptionType: 'Redemption Type',
    GiftingOption: 'Gifting Option',

    Public: 'Public',
    MainBodyText: 'Main body text',
    AddAccordion: 'Add accordion',

    Next: 'Next',
    Cancel: 'Cancel',
    Browse: 'Browse',

    ImportReward: 'Import Reward',
    StartDate: 'Start Date',
    EndDate: 'End Date',
    OperatingDays: 'Operating Days',
    From: 'From',
    DiscountStartDate: 'Discount Start Date',
    DiscountEndDate: 'Discount End Date',
    ID: 'ID',
    CashValue: 'Cash Value',

    PointsValue: 'Points Value',
    LoyaltyProgram: 'Loyalty Program',
    Identifier: 'Identifier',
    Action: 'Action',
    ThisRewardsIsCurrentlyFREE: 'This reward is currently FREE',
    ReActivateSellingPrice: 'Re-activate a selling price below or add a new one.',
    AddSellingPrice: 'Add Selling Price',
    EditSellingPrice: 'Edit Selling Price',
    HowDoYouValueYourRewards: 'How do you value your rewards?',
    CostPrice: 'Cost Price',
    SocialMedia: 'Social Media',
    Title: 'Title',
    Image: 'Image',
    iOSAppLink: 'iOS app link',
    AndroidAppLink: 'Android app link',
    WebLink: 'Web link',
    AddCustomField: 'Add custom field',
    CustomField: 'Custom field',
    TypeInFieldName: 'Type in field name',
    TypeInFieldValue: 'Type in field value',
    SelectCodeType: 'Select code type',
    TypeOfCode: 'Type of Code',
    QRCode: 'QR code',
    BarCode: 'Bar Code',
    URL: 'URL',
    SelectFile: 'Select File',
    ChooseFile: 'Choose File',
    DownloadSample: 'Download Sample (Accepted Format: .xlsx/.csv files only)',
    Limits: 'Limits',
    IssueAMaximumOf: 'Issue a maximum of',
    RewardsPer: 'Rewards per',
    Second: 'Second',
    Minutes: 'Minutes',
    Hours: 'Hours',
    Days: 'Days',
    Weeks: 'Weeks',
    Months: 'Months',
    Year: 'Year',
    DisplayEvenWhenIssuanceLimitIsReached: 'Display even when issuance limit is reached',
    RewardRedemptionLimit: 'Reward Redemption Limit',
    LimitRedemptionsTo: 'Limit redemptions to',
    UserLimit: 'User Limit',
    UserLimitPerPeriod: 'User Limit per period',
    DisplayEvenWhenLimitIsReached: 'Display even when limit is reached',
    ValidityPeriod: 'Validity Period',
    SelectType: 'Select Type',
    Period: 'PERIOD',
    IssuanceDate: 'ISSUANCE DATE',
    EndOfMonth: 'END OF MONTH',
    RedemptionOutcomes: 'Redemption Outcomes',
    AddOutcome: 'Add Outcome',
    Online: 'ONLINE',
    Offline: 'OFFLINE',
    MerchantPIN: 'MERCHANT PIN',
    Pin: 'PIN',
    APIRedemption: 'API REDEMPTION',
    SetCountdown: 'Set Countdown',
    SelectGiftingOption: 'Select Gifting Option',
    ScheduleLaunch: 'Schedule Launch',
    MapYourRewardTo: 'Map your reward to',
    Include: 'Include',
    SneakPeek: 'Sneak Peek',
    SelectLoyaltyProgram: 'Please select your loyalty Program',
    PickAudienceList: 'Pick an audience list',
    UploadNewList: 'Upload New List',
    AcceptedFormat: 'Accepted Format: .xlsx/.csv files only',
    AudienceNotInList: 'Audience not in the list?',
    PleaseSelectAMerchant: 'Please select a merchant',
    StartDateEndDateRequired: 'Start date & end date required',
    EndDateCannotBeBeforeStartDate: 'End date cannot be before start date',

    ValidityAtLeastOneHour: 'Validity has to be at least 1 hour',
    ValidFor: 'Valid for',
    GraceTime: 'Grace Time',
    GraceTimeCannotBeNegative: 'Grace time cannot be negative',
    Yes: 'Yes',
    No: 'No',

    RewardInfo: 'Reward Info',
    RewardMechanics: 'Reward Mechanics',
    ActivationDate: 'Activation Date',

    RewardMustHaveAName: 'Reward must have a name',
    AllowedFileTypes: 'Allowed: .jpg, .png, .svg',
    EitherAniOSOrAndroidOrWebLinkIsRequired: 'Either an iOS or Android or Web link is required',
    Delete: 'Delete',
    InsertPhoto: 'Insert Photo',
    SelectImageType: 'Select png, svg, jpeg, or gif images',
    Info: 'Info',
    Audience: 'Audience',
    SelectAudience: 'Select Audience',
    Review: 'Review',
    Mechanics: 'Mechanics',
    RewardDetails: 'Reward Details',
    SelectDate: 'Select date',
    SelectTime: 'Select time'
  },
  [VoucherCodeSetupPageKey]: {
    NoCode: 'No Code',
    Single: 'Single',
    SystemGenerated: 'System Generated',
    UserUpload: 'User Upload',
    Inventory: 'Inventory',
    Stash: 'Stash',
    VoucherCodeSetup: 'Voucher Code Setup',
    SelectCodeType: 'Select Code Type'
  },
  [RewardSellingModalKey]: {
    SelectLoyaltyProgram: 'Select loyalty program',
    SelectTier: 'Select tier',
    Cash: 'Cash',
    PointsRegularPrice: 'Points (regular price)',
    PointsDiscountPrice: 'Points (discount price)',
    egHundred: 'e.g. 100',
    Optional: 'Optional',
    SelectGLAccount: 'Select GL Account',
    GLAccountType: 'GL Account Type',
    GLAccountName: 'GL Account Name '
  },
  [StatusTextKey]: {
    active: 'Active',
    inactive: 'Inactive',
    draft: 'Draft',
    processing: 'Processing',
    pending: 'Pending',
    approved: 'Approved',
    scheduled: 'Scheduled',
    redeemed: 'Redeemed',
    ended: 'Ended',
    failed: 'Failed',
    fail: 'Fail',
    expired: 'Expired',
    public: 'Public'
  }
};
