import { RewardModule } from 'utils/i18n/TModules';

// Translation Keys
export const RewardListPageKey = `RewardListPage`;
export const VoucherCodeSetupPageKey = `VoucherCodeSetup`;
export const RewardSellingModalKey = `SellingPriceModalKey`;
export const StatusTextKey = `StatusTextKey`;
// export const RewardDetailsPage = `RewardDetailsPage`;

// Translation Paths
export const TRewardListPage = `${RewardModule}:${RewardListPageKey}`;
export const TVoucherCodeSetupPage = `${RewardModule}:${VoucherCodeSetupPageKey}`;
export const TRewardSellingModal = `${RewardModule}:${RewardSellingModalKey}`;
export const TStatusText = `${RewardModule}:${StatusTextKey}`;
// export const TRewardDetailsPage = `${moduleName}:${RewardDetailsPage}`;
