import React, { memo } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import PropTypes from 'prop-types';

import { useSimpleLoyaltyList } from 'api/simple';
import { TRewardSellingModal } from 'containers/Reward/i18n/TModules';
import useMultiLanguageTranslation from 'hooks/useMultiLanguageTranslation';

interface LoyaltyProgramSelectProps extends SelectProps {
  value: number;
  onChange?: (loyaltyId: number, program: any) => void;
  disabledOptionsIds?: number[];
}

export const LoyaltyProgramSelect: React.FC<LoyaltyProgramSelectProps> = ({
  value,
  onChange,
  disabledOptionsIds = [],
  ...props
}) => {
  const { data = [], isLoading } = useSimpleLoyaltyList();
  const { t: translate } = useMultiLanguageTranslation();

  const onSelectionChange = id => {
    const program = data.find(item => item.id === id);
    onChange(id, program);
  };

  const options = data.map(program => ({
    value: program.id,
    label: program.name,
    disabled: disabledOptionsIds.indexOf(program.id) > -1
  }));

  return (
    <Select
      value={isLoading ? null : value}
      loading={isLoading}
      onChange={onSelectionChange}
      placeholder={`${translate(`${TRewardSellingModal}.SelectLoyaltyProgram`)}`}
      options={options}
      {...props}
      onBlur={e => e.preventDefault()}
    />
  );
};

LoyaltyProgramSelect.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func
};

export default memo(LoyaltyProgramSelect);
