import {
  RewardListPageKey,
  VoucherCodeSetupPageKey,
  RewardSellingModalKey,
  StatusTextKey
} from '../../TModules';

export const translations = {
  [RewardListPageKey]: {
    Reward: 'Ricompensa',
    List: 'Lista',
    All: 'Tutti',
    Draft: 'Bozza',
    Pending: 'In sospeso',
    Scheduled: 'Programmato',
    Active: 'Attivo',
    Ended: 'Terminato',
    Inactive: 'Inattivo',
    TransactionIssued: 'Transazione emessa',
    Private: 'Privato',
    System: 'Sistema',
    SellingFast: 'Vendita rapida',
    SoldOut: 'Esaurito',
    Subtitle: 'Sottotitolo',
    Stackable: 'Impilabile',

    Name: 'Nome',
    Merchant: 'Commerciante',
    Status: 'Stato',
    Balance: 'Saldo',
    Redeemed: 'Riscattato',
    Voided: 'Stornato',
    SellingFrom: 'Vendita da',
    SellingTo: 'Vendita fino a',
    Actions: 'Azioni',
    RequestType: 'Tipo di richiesta',
    Comments: 'Commenti',
    LastUpdated: 'Ultimo aggiornamento',

    CreateNew: 'Crea nuovo',
    Edit: 'Modifica',
    DownloadCodes: 'Scarica codici',
    DuplicateReward: 'Duplica ricompensa',
    Deactivate: 'Disattiva',
    DownloadDepletedCodes: 'Scarica codici esauriti',
    UnassginedVouchers: 'Buoni non assegnati',
    ReplinishVouchers: 'Riempire i buoni',
    Deplete: 'Esaure',
    Replinish: 'Riempire',
    AppendBudget: 'Aggiungi budget',
    Approve: 'Approva',

    Type: 'Tipo',
    RewardName: 'Nome della ricompensa',
    Description: 'Descrizione',
    StepsToRedeem: 'Passaggi per riscattare',
    SellingPeriod: 'Periodo di vendita',
    ActiveAt: 'Attivo a',
    TermsAndConditions: 'Termini e condizioni',
    RiskDisclaimer: 'Avvertenza sui rischi',
    BrandName: 'Nome del marchio',
    Brands: 'Marchi',
    Tags: 'Tag',
    Categories: 'Categorie',
    Labels: 'Etichette',
    Catalogues: 'Cataloghi',
    UploadedPhotos: 'Foto caricate',
    UploadedPhoto: 'Foto caricata',
    UploadMultipleImages: 'Carica più immagini',
    UploadAFile: 'Carica un file',
    WhereCanThisbeRewardDisplayed: 'Dove può essere visualizzata questa ricompensa',
    RewardCost: 'Costo della ricompensa',
    SellingPrice: 'Prezzo di vendita',
    CustomFields: 'Campi personalizzati',

    AudienceInfo: 'Informazioni sul pubblico',
    AudienceAttributes: 'Attributi del pubblico',
    AudienceList: 'Elenco del pubblico',
    NoFiltersSelected: 'Nessun filtro selezionato',
    NoListSelected: 'Nessun elenco selezionato',

    RewardsMechanics: 'Meccaniche di ricompensa',
    CodeType: 'Tipo di codice',
    Code: 'Codice',
    VoucherCodeType: 'Tipo di codice voucher',
    RewardIssuanceLimit: 'Limite di emissione della ricompensa',
    TotalRedemptionLimit: 'Limite totale di riscatto',
    RedemptionLimitsPerPeriod: 'Limiti di riscatto per periodo',
    RewardIssuancePerPeriodLimit: 'Limite di emissione della ricompensa per periodo',
    UserLimits: 'Limiti utente',
    UserLimitsPerPeriod: 'Limiti utente per periodo',
    VoucherValidity: 'Validità del voucher',
    RedemptionType: 'Tipo di riscatto',
    GiftingOption: 'Opzione regalo',

    Public: 'Pubblico',
    MainBodyText: 'Testo principale',
    AddAccordion: 'Aggiungi fisarmonica',

    Next: 'Avanti',
    Cancel: 'Annulla',
    Browse: 'Sfoglia',

    ImportReward: 'Importa ricompensa',
    StartDate: 'Data di inizio',
    EndDate: 'Data di fine',
    OperatingDays: 'Giorni operativi',
    From: 'Da',
    DiscountStartDate: 'Data di inizio sconto',
    DiscountEndDate: 'Data di fine sconto',
    ID: 'ID',
    CashValue: 'Valore in contanti',

    PointsValue: 'Valore in punti',
    LoyaltyProgram: 'Programma fedeltà',
    Identifier: 'Identificatore',
    Action: 'Azione',
    ThisRewardsIsCurrentlyFREE: 'Questa ricompensa è attualmente GRATUITA',
    ReActivateSellingPrice: 'Riattiva un prezzo di vendita qui sotto o aggiungine uno nuovo',
    AddSellingPrice: 'Aggiungi prezzo di vendita',
    EditSellingPrice: 'Modifica prezzo di vendita',
    HowDoYouValueYourRewards: 'Come valuti le tue ricompense?',
    CostPrice: 'Prezzo di costo',
    SocialMedia: 'Social media',
    Title: 'Titolo',
    Image: 'Immagine',
    iOSAppLink: 'Link app iOS',
    AndroidAppLink: 'Link app Android',
    WebLink: 'Link web',
    AddCustomField: 'Aggiungi campo personalizzato',
    CustomField: 'Campo personalizzato',
    TypeInFieldName: 'Inserisci il nome del campo',
    TypeInFieldValue: 'Inserisci il valore del campo',
    SelectCodeType: 'Seleziona tipo di codice',
    TypeOfCode: 'Tipo di codice',
    QRCode: 'Codice QR',
    BarCode: 'Codice a barre',
    URL: 'URL',
    SelectFile: 'Seleziona file',
    ChooseFile: 'Scegli file',
    DownloadSample: 'Scarica esempio (Formato accettato: solo file .xlsx/.csv)',
    Limits: 'Limiti',
    IssueAMaximumOf: 'Emetti un massimo di',
    RewardsPer: 'Ricompense per',
    Second: 'Secondo',
    Minutes: 'Minuti',
    Hours: 'Ore',
    Days: 'Giorni',
    Weeks: 'Settimane',
    Months: 'Mesi',
    Year: 'Anno',
    DisplayEvenWhenIssuanceLimitIsReached:
      'Visualizza anche quando il limite di emissione è raggiunto',
    RewardRedemptionLimit: 'Limite di riscatto della ricompensa',
    LimitRedemptionsTo: 'Limita i riscatti a',
    UserLimit: 'Limite utente',
    UserLimitPerPeriod: 'Limite utente per periodo',
    DisplayEvenWhenLimitIsReached: 'Visualizza anche quando il limite è raggiunto',
    ValidityPeriod: 'Periodo di validità',
    SelectType: 'Seleziona tipo',
    Period: 'PERIODO',
    IssuanceDate: 'DATA DI EMISSIONE',
    EndOfMonth: 'FINE DEL MESE',
    RedemptionOutcomes: 'Risultati di riscatto',
    AddOutcome: 'Aggiungi risultato',
    Online: 'ONLINE',
    Offline: 'OFFLINE',
    MerchantPIN: 'PIN DEL COMMERCIANTE',
    Pin: 'PIN',
    APIRedemption: 'RISCATTO API',
    SetCountdown: 'Imposta conto alla rovescia',
    SelectGiftingOption: 'Seleziona opzione regalo',
    ScheduleLaunch: 'Pianifica lancio',
    MapYourRewardTo: 'Mappa la tua ricompensa a',
    Include: 'Includi',
    SneakPeek: 'Anteprima',
    SelectLoyaltyProgram: 'Seleziona il tuo programma fedeltà',
    PickAudienceList: 'Seleziona elenco pubblico',
    UploadNewList: 'Carica nuovo elenco',
    AcceptedFormat: 'Formato accettato: solo file .xlsx/.csv',
    AudienceNotInList: "Pubblico non nell'elenco?",
    PleaseSelectAMerchant: 'Seleziona un commerciante',
    StartDateEndDateRequired: 'Data di inizio e data di fine richieste',
    EndDateCannotBeBeforeStartDate: 'La data di fine non può essere precedente alla data di inizio',

    ValidityAtLeastOneHour: 'La validità deve essere di almeno 1 ora',
    ValidFor: 'Valido per',
    GraceTime: 'Tempo di grazia',
    GraceTimeCannotBeNegative: 'Il tempo di grazia non può essere negativo',
    Yes: 'Sì',
    No: 'No',

    RewardInfo: 'Informazioni sulla ricompensa',
    RewardMechanics: 'Meccaniche di ricompensa',
    ActivationDate: 'Data di attivazione',

    RewardMustHaveAName: 'La ricompensa deve avere un nome',
    AllowedFileTypes: 'Consentito: .jpg, .png, .svg',
    EitherAniOSOrAndroidOrWebLinkIsRequired: 'È richiesto un link iOS, Android o Web',
    Delete: 'Elimina',
    InsertPhoto: 'Inserisci foto',
    SelectImageType: 'Seleziona immagini png, svg, jpeg o gif',
    Info: 'Info',
    Audience: 'Pubblico',
    SelectAudience: 'Seleziona pubblico',
    Review: 'Revisione',
    Mechanics: 'Meccaniche',
    RewardDetails: 'Dettagli della ricompensa',
    SelectDate: 'Seleziona data',
    SelectTime: 'Seleziona ora'
  },
  [VoucherCodeSetupPageKey]: {
    NoCode: 'Nessun codice',
    Single: 'Singolo',
    SystemGenerated: 'Generato dal sistema',
    UserUpload: 'Caricamento utente',
    Inventory: 'Inventario',
    Stash: 'Scorta',
    VoucherCodeSetup: 'Configurazione codice voucher',
    SelectCodeType: 'Seleziona tipo di codice'
  },
  [RewardSellingModalKey]: {
    SelectLoyaltyProgram: 'Seleziona programma fedeltà',
    SelectTier: 'Seleziona livello',
    Cash: 'Contanti',
    PointsRegularPrice: 'Punti (prezzo regolare)',
    PointsDiscountPrice: 'Punti (prezzo scontato)',
    egHundred: 'ad es. 100',
    Optional: 'Opzionale',
    SelectGLAccount: 'Seleziona GL Account',
    GLAccountType: 'Tipo di conto GL',
    GLAccountName: 'Nome del conto GL '
  },
  [StatusTextKey]: {
    active: 'Attivo',
    inactive: 'Inattivo',
    draft: 'Bozza',
    processing: 'In elaborazione',
    pending: 'In attesa',
    approved: 'Approvato',
    scheduled: 'Programmato',
    redeemed: 'Riscattato',
    ended: 'Terminato',
    failed: 'Fallito',
    fail: 'Errore',
    expired: 'Scaduto',
    public: 'Pubblico'
  }
};
