import useSWR from 'swr';

import { buildQuery, fetcher } from 'api/utils';
import { IDetailResponse, IPaginatedResponse } from 'utils/types';

export type TransferRequestStatus = 'new' | 'in_progress' | 'completed' | 'declined' | 'cancelled';

export type TransferRequestType =
  | 'BR-PT'
  | 'BR-CashCred'
  | 'BR-SMAC'
  | 'CC-BRCP'
  | 'CC-CashCred'
  | 'CC-SMAC';

export interface ITransferRequest {
  id: number;
  state: TransferRequestStatus;
  amount: number;
  created_at: string | Date;
  reason?: string;
  receiver: string;
  request_type: TransferRequestType;
  sender: string;
  updated_at: string;
  properties: {
    state: TransferRequestStatus;
    amount: string;
    sender: string;
    receiver: string;
    request_type: TransferRequestType;
    sender_last_name: string;
    sender_cif_number: string;
    sender_first_name: string;
    receiver_last_name: string;
    receiver_cif_number: string;
    receiver_first_name: string;
    updated_by: string;
    updated_by_full_name: string;
    employee_id: string;

    senders_stored_value_transaction_id?: number;
    sign?: string;
    sender_cif?: string;
    updated_at?: string;
    mask_sender?: string;
    receiver_cif?: string;
    mask_receiver?: string;
    destination_card?: string;
    destination_text?: string;
    transferred_from?: string;
    reason?: string;
  };
}

export const useTransferRequest = (id: number | string) => {
  const { data, isLoading, mutate } = useSWR<IDetailResponse<ITransferRequest>>(
    id ? `/v4/dash/custom/bdo/point_transfers/${id}` : null,
    fetcher
  );

  return { data: data?.data, isLoading, mutate };
};

export const useTransferRequestList = (params: object) => {
  const { data, isLoading } = useSWR<IPaginatedResponse<ITransferRequest>>(
    `/v4/dash/custom/bdo/point_transfers?${buildQuery(params)}`,
    fetcher
  );

  return { data: data?.data, meta: data?.meta, isLoading };
};

export const useTransferReasonList = (params: { state: TransferRequestStatus }) => {
  const { data, isLoading } = useSWR<{ reasons: string[] }>(
    `/v4/dash/custom/bdo/transfer_reasons?${buildQuery(params)}`,
    fetcher
  );

  return { data: data?.reasons, isLoading };
};
