import React from 'react';
import { useForm } from 'react-hook-form';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'antd';
import styled from 'styled-components';
import * as yup from 'yup';

import {
  ITransferRequest,
  TransferRequestStatus,
  useTransferReasonList
} from 'api/bdo/transfer-requests';
import { RHFItemSelect } from 'components/rhf';
import { usePointsFormat } from 'hooks';
import API from 'utils/request';

import { getColorForStatus, TransferRequestTypeLabels } from '../utils';

interface UpdateRequestStatusModalProps {
  request: ITransferRequest;
  state: TransferRequestStatus;
}

const verticalLayout = {
  labelAlign: 'left',
  labelCol: { xs: 24 },
  wrapperCol: { xs: 24 }
} as const;

const validationSchema = yup.object().shape({
  reason: yup.string().nullable().required('Please select a reason')
});

const StyledModal = styled(Modal)<{ state: TransferRequestStatus }>`
  .ant-modal-header {
    background-color: ${({ state }) => getColorForStatus(state)};
  }
  .ant-modal-title {
    color: #ffffff;
  }
  svg {
    fill: #ffffff;
  }

  .ant-btn {
    &:hover {
      opacity: 0.85;
    }
  }
  .ant-btn-default {
    color: ${({ state }) => getColorForStatus(state)};
    border-color: ${({ state }) => getColorForStatus(state)};
  }
  .ant-btn-primary {
    color: #fff;
    background-color: ${({ state }) => getColorForStatus(state)};
    border-color: ${({ state }) => getColorForStatus(state)};
  }
`;

const UpdateRequestStatusModal = NiceModal.create(
  ({ request, state }: UpdateRequestStatusModalProps) => {
    const modal = useModal();
    const { control, handleSubmit } = useForm({
      defaultValues: { state, reason: null },
      resolver: yupResolver(validationSchema)
    });

    const { data: reasons, isLoading } = useTransferReasonList({ state });

    const reasonOptions = reasons?.map(reason => ({ value: reason, label: reason }));

    const onSubmit = values => {
      API.patch(`/v4/dash/custom/bdo/point_transfers/${request.id}`, values)
        .then(res => {
          modal.resolve(res?.data?.data);
        })
        .catch(err => {
          modal.reject(err);
        })
        .finally(() => {
          modal.hide();
        });
    };

    let verb = '';
    let title = '';
    let okText = '';

    switch (state) {
      case 'completed':
        verb = 'approve';
        title = 'Complete request?';
        okText = 'Confirm';
        break;
      case 'declined':
        verb = 'decline';
        title = 'Decline request?';
        okText = 'Confirm decline';
        break;
      case 'cancelled':
        verb = 'cancel';
        title = 'Cancel request?';
        okText = 'Cancel transfer request';
        break;
    }

    const { formatPoints } = usePointsFormat();

    return (
      <StyledModal
        {...antdModalV5(modal)}
        onOk={handleSubmit(onSubmit)}
        title={title}
        okText={okText}
        state={state}
        cancelText="Go back"
        okButtonProps={{ disabled: isLoading }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <p>
            Are you sure you want to {verb} the request?
            <br />
            This action cannot be undone.
          </p>
          <p>
            <span>
              <span style={{ display: 'inline-block', width: 120 }}>Points:</span>{' '}
              <strong>{formatPoints(request.amount)}</strong>
            </span>
            <br />
            <span>
              <span style={{ display: 'inline-block', width: 120 }}>Request type:</span>{' '}
              <strong>{TransferRequestTypeLabels[request.request_type]}</strong>
            </span>
          </p>

          <RHFItemSelect
            formItemProps={{ ...verticalLayout }}
            control={control}
            name="reason"
            label="Reason"
            placeholder="Select from list"
            loading={isLoading}
            options={reasonOptions}
          />
        </form>
      </StyledModal>
    );
  }
);

export default UpdateRequestStatusModal;
