import {
  RewardListPageKey,
  RewardSellingModalKey,
  StatusTextKey,
  VoucherCodeSetupPageKey
} from '../../TModules';

export const translations = {
  [RewardListPageKey]: {
    Reward: 'Récompense',
    List: 'Liste',
    All: 'Tout',
    Draft: 'Brouillon',
    Pending: 'En attente',
    Scheduled: 'Programmé',
    Active: 'Actif',
    Ended: 'Terminé',
    Inactive: 'Inactif',
    TransactionIssued: 'Transaction émise',
    Private: 'Privé',
    System: 'Système',
    SellingFast: 'Vente rapide',
    SoldOut: 'Épuisé',
    Subtitle: 'Sous-titre',
    Stackable: 'Empilable',

    Name: 'Nom',
    Merchant: 'Marchand',
    Status: 'Statut',
    Balance: 'Solde',
    Redeemed: 'Réduit',
    Voided: 'Storné',
    SellingFrom: 'Vente à partir de',
    SellingTo: "Vente jusqu'à",
    Actions: 'Actions',
    RequestType: 'Type de demande',
    Comments: 'Commentaires',
    LastUpdated: 'Dernière mise à jour',

    CreateNew: 'Créer nouveau',
    Edit: 'Modifier',
    DownloadCodes: 'Télécharger les codes',
    DuplicateReward: 'Dupliquer la récompense',
    Deactivate: 'Désactiver',
    DownloadDepletedCodes: 'Télécharger les codes épuisés',
    UnassginedVouchers: 'Bons non attribués',
    ReplinishVouchers: 'Reconstituer les bons',
    Deplete: 'Épuiser',
    Replinish: 'Reconstituer',
    AppendBudget: 'Ajouter un budget',
    Approve: 'Approuver',

    Type: 'Type',
    RewardName: 'Nom de la récompense',
    Description: 'Description',
    StepsToRedeem: 'Étapes pour racheter',
    SellingPeriod: 'Période de vente',
    ActiveAt: 'Actif à',
    TermsAndConditions: 'Termes et conditions',
    RiskDisclaimer: 'Avertissement de risque',
    BrandName: 'Nom de la marque',
    Brands: 'Marques',
    Tags: 'Tags',
    Categories: 'Catégories',
    Labels: 'Étiquettes',
    Catalogues: 'Catalogues',
    UploadedPhotos: 'Photos téléchargées',
    UploadedPhoto: 'Photo téléchargée',
    UploadMultipleImages: 'Télécharger plusieurs images',
    UploadAFile: 'Télécharger un fichier',
    WhereCanThisbeRewardDisplayed: 'Où cette récompense peut-elle être affichée',
    RewardCost: 'Coût de la récompense',
    SellingPrice: 'Prix de vente',
    CustomFields: 'Champs personnalisés',

    AudienceInfo: "Informations sur l'audience",
    AudienceAttributes: "Attributs de l'audience",
    AudienceList: "Liste d'audience",
    NoFiltersSelected: 'Aucun filtre sélectionné',
    NoListSelected: 'Aucune liste sélectionnée',

    RewardsMechanics: 'Mécanismes de récompense',
    CodeType: 'Type de code',
    Code: 'Code',
    VoucherCodeType: 'Type de code de bon',
    RewardIssuanceLimit: "Limite d'émission de récompense",
    TotalRedemptionLimit: 'Limite totale de rachat',
    RedemptionLimitsPerPeriod: 'Limites de rachat par période',
    RewardIssuancePerPeriodLimit: "Limite d'émission de récompense par période",
    UserLimits: 'Limites utilisateur',
    UserLimitsPerPeriod: 'Limites utilisateur par période',
    VoucherValidity: 'Validité du bon',
    RedemptionType: 'Type de rachat',
    GiftingOption: 'Option de cadeau',

    Public: 'Public',
    MainBodyText: 'Texte principal',
    AddAccordion: 'Ajouter un accordéon',

    Next: 'Suivant',
    Cancel: 'Annuler',
    Browse: 'Parcourir',

    ImportReward: 'Importer une récompense',
    StartDate: 'Date de début',
    EndDate: 'Date de fin',
    OperatingDays: "Jours d'exploitation",
    From: 'De',
    DiscountStartDate: 'Date de début de remise',
    DiscountEndDate: 'Date de fin de remise',
    ID: 'ID',
    CashValue: 'Valeur en espèces',

    PointsValue: 'Valeur en points',
    LoyaltyProgram: 'Programme de fidélité',
    Identifier: 'Identifiant',
    Action: 'Action',
    ThisRewardsIsCurrentlyFREE: 'Cette récompense est actuellement GRATUITE',
    ReActivateSellingPrice: 'Réactiver un prix de vente ci-dessous ou en ajouter un nouveau',
    AddSellingPrice: 'Ajouter un prix de vente',
    EditSellingPrice: 'Modifier le prix de vente',
    HowDoYouValueYourRewards: 'Comment évaluez-vous vos récompenses ?',
    CostPrice: 'Prix de revient',
    SocialMedia: 'Médias sociaux',
    Title: 'Titre',
    Image: 'Image',
    iOSAppLink: "Lien de l'app iOS",
    AndroidAppLink: "Lien de l'app Android",
    WebLink: 'Lien web',
    AddCustomField: 'Ajouter un champ personnalisé',
    CustomField: 'Champ personnalisé',
    TypeInFieldName: 'Saisir le nom du champ',
    TypeInFieldValue: 'Saisir la valeur du champ',
    SelectCodeType: 'Sélectionner le type de code',
    TypeOfCode: 'Type de code',
    QRCode: 'Code QR',
    BarCode: 'Code-barres',
    URL: 'URL',
    SelectFile: 'Sélectionner un fichier',
    ChooseFile: 'Choisir un fichier',
    DownloadSample: "Télécharger l'exemple (Format accepté : fichiers .xlsx/.csv uniquement)",
    Limits: 'Limites',
    IssueAMaximumOf: 'Émettre un maximum de',
    RewardsPer: 'Récompenses par',
    Second: 'Seconde',
    Minutes: 'Minutes',
    Hours: 'Heures',
    Days: 'Jours',
    Weeks: 'Semaines',
    Months: 'Mois',
    Year: 'Année',
    DisplayEvenWhenIssuanceLimitIsReached:
      "Afficher même lorsque la limite d'émission est atteinte",
    RewardRedemptionLimit: 'Limite de rachat de récompense',
    LimitRedemptionsTo: 'Limiter les rachats à',
    UserLimit: 'Limite utilisateur',
    UserLimitPerPeriod: 'Limite utilisateur par période',
    DisplayEvenWhenLimitIsReached: 'Afficher même lorsque la limite est atteinte',
    ValidityPeriod: 'Période de validité',
    SelectType: 'Sélectionner le type',
    Period: 'PÉRIODE',
    IssuanceDate: "DATE D'ÉMISSION",
    EndOfMonth: 'FIN DU MOIS',
    RedemptionOutcomes: 'Résultats de rachat',
    AddOutcome: 'Ajouter un résultat',
    Online: 'EN LIGNE',
    Offline: 'HORS LIGNE',
    MerchantPIN: 'PIN MARCHAND',
    Pin: 'PIN',
    APIRedemption: 'RACHAT API',
    SetCountdown: 'Définir le compte à rebours',
    SelectGiftingOption: "Sélectionner l'option de cadeau",
    ScheduleLaunch: 'Planifier le lancement',
    MapYourRewardTo: 'Associer votre récompense à',
    Include: 'Inclure',
    SneakPeek: 'Aperçu',
    SelectLoyaltyProgram: 'Veuillez sélectionner votre programme de fidélité',
    PickAudienceList: "Choisir une liste d'audience",
    UploadNewList: 'Télécharger une nouvelle liste',
    AcceptedFormat: 'Format accepté : fichiers .xlsx/.csv uniquement',
    AudienceNotInList: 'Audience non dans la liste ?',
    PleaseSelectAMerchant: 'Veuillez sélectionner un marchand',
    StartDateEndDateRequired: 'Date de début et date de fin requises',
    EndDateCannotBeBeforeStartDate: 'La date de fin ne peut pas être antérieure à la date de début',

    ValidityAtLeastOneHour: "La validité doit être d'au moins 1 heure",
    ValidFor: 'Valide pour',
    GraceTime: 'Délai de grâce',
    GraceTimeCannotBeNegative: 'Le délai de grâce ne peut pas être négatif',
    Yes: 'Oui',
    No: 'Non',

    RewardInfo: 'Informations sur la récompense',
    RewardMechanics: 'Mécanismes de récompense',
    ActivationDate: "Date d'activation",

    RewardMustHaveAName: 'La récompense doit avoir un nom',
    AllowedFileTypes: 'Autorisé : .jpg, .png, .svg',
    EitherAniOSOrAndroidOrWebLinkIsRequired: 'Un lien iOS ou Android ou Web est requis',
    Delete: 'Supprimer',
    InsertPhoto: 'Insérer une photo',
    SelectImageType: 'Sélectionner des images png, svg, jpeg ou gif',
    Info: 'Info',
    Audience: 'Audience',
    SelectAudience: 'Sélectionner une audience',
    Review: 'Vérifier',
    Mechanics: 'Mécanismes',
    RewardDetails: 'Détails de la récompense',
    SelectDate: 'Sélectionner une date',
    SelectTime: 'Sélectionner un horaire'
  },
  [VoucherCodeSetupPageKey]: {
    NoCode: 'Pas de code',
    Single: 'Unique',
    SystemGenerated: 'Généré par le système',
    UserUpload: 'Téléchargement utilisateur',
    Inventory: 'Inventaire',
    Stash: 'Réserve',
    VoucherCodeSetup: 'Configuration du code de bon',
    SelectCodeType: 'Sélectionner le type de code'
  },
  [RewardSellingModalKey]: {
    SelectLoyaltyProgram: 'Veuillez sélectionner votre programme de fidélité',
    SelectTier: 'Sélectionner le niveau',
    Cash: 'Espèces',
    PointsRegularPrice: 'Points (prix régulier)',
    PointsDiscountPrice: 'Points (prix réduit)',
    egHundred: 'par ex. 100',
    Optional: 'Optionnel',
    SelectGLAccount: 'Sélectionner le compte GL',
    GLAccountType: 'Type de compte GL',
    GLAccountName: 'Nom du compte GL'
  },
  [StatusTextKey]: {
    active: 'Actif',
    inactive: 'Inactif',
    draft: 'Brouillon',
    processing: 'Traitement',
    pending: 'En attente',
    approved: 'Approuvé',
    scheduled: 'Programmé',
    redeemed: 'Utilisé',
    ended: 'Terminé',
    failed: 'Échoué',
    fail: 'Échec',
    expired: 'Expiré',
    public: 'Public'
  }
};
