import { useTranslation } from 'react-i18next';

import { FEATURES } from 'appConstants';
import { fallbackLanguage, PREFERRED_LANGUAGE_LOCAL_STORAGE_KEY } from 'utils/i18n';

import useFeatureFlags from './useFeatureFlags';

const useMultiLanguageTranslation = () => {
  const {
    t,
    i18n: { changeLanguage, resolvedLanguage }
  } = useTranslation();
  const featureFlags = useFeatureFlags();
  if (!featureFlags[FEATURES.LANGUAGE_SELECTOR.key] && resolvedLanguage !== fallbackLanguage) {
    changeLanguage(fallbackLanguage);
    localStorage.setItem(PREFERRED_LANGUAGE_LOCAL_STORAGE_KEY, fallbackLanguage);
  }
  return { t };
};

export default useMultiLanguageTranslation;
