import React from 'react';
import { useParams } from 'react-router-dom';
import { useModal } from '@ebay/nice-modal-react';
import { Button, Col, notification, Row, Skeleton } from 'antd';
import moment from 'moment';

import {
  ITransferRequest,
  TransferRequestStatus,
  useTransferRequest
} from 'api/bdo/transfer-requests';
import { DetailRow, WrapperPanel } from 'components';
import BDOTransferRequestAuditLog from 'components/AuditLog/BDOTransferRequestAuditLog';
import { PageContent, PageHeader } from 'components/page';
import { usePermissions, usePointsFormat } from 'hooks';
import API from 'utils/request';

import { dateTimeFormat } from '../../constants';
import RequestStatusTag from '../components/RequestStatusTag';
import { StyledButton } from '../components/StyledButton';
import UpdateRequestStatusModal from '../components/UpdateRequestStatusModal';
import { TransferRequestAccountTypes, TransferRequestTypeLabels } from '../utils';

const Placeholder = () => (
  <Skeleton title={false} paragraph={{ rows: 1, width: 120 }} style={{ marginBottom: '-1em' }} />
);

const DetailRowWithLoading = ({ data, label, loading }) => (
  <DetailRow label={label} data={loading ? <Placeholder /> : data} />
);

const BDOTransferRequest = () => {
  const { id: requestId } = useParams<{ id: string }>();
  const updateStatusModal = useModal(UpdateRequestStatusModal);
  const { formatPoints } = usePointsFormat();

  const { data, isLoading, mutate } = useTransferRequest(requestId);

  const { canEdit } = usePermissions('transfer_requests');

  const disabledAction = !canEdit || isLoading;

  const setToInProgress = () => {
    API.patch(`/v4/dash/custom/bdo/point_transfers/${requestId}`, {
      state: 'in_progress',
      reason: ''
    })
      .then(() => {
        notification.success({ message: 'Status changed to ‘In progress’' });
        mutate();
      })
      .catch(err => {
        notification.error({ message: 'Error happened', description: err?.message });
      });
  };
  const completeRequest = () => {
    updateStatus('completed');
  };
  const declineRequest = () => {
    updateStatus('declined');
  };
  const cancelRequest = () => {
    updateStatus('cancelled');
  };

  const updateStatus = (state: TransferRequestStatus) => {
    updateStatusModal
      .show({ request: data, state })
      .then((data: ITransferRequest | null) => {
        let message = '';
        let description = '';

        switch (state) {
          case 'completed':
            message = 'Approval successful';
            description = 'The transfer request has been approved.';
            break;
          case 'declined':
            message = data?.properties?.senders_stored_value_transaction_id
              ? 'Decline successful'
              : 'Request Declined Without Refund';
            description = data?.properties?.senders_stored_value_transaction_id
              ? 'The transfer request has been declined.'
              : 'The request was successfully declined, but there was no transaction to refund. You do not need to take any further action for this request.';
            break;
          case 'cancelled':
            message = 'Cancellation successful';
            description = 'The transfer request has been cancelled.';
            break;
        }

        notification.success({ message, description });
      })
      .catch(err => {
        let message = '';
        let description = err?.message;

        switch (state) {
          case 'completed':
            message = 'The request could not be approved';
            break;
          case 'declined':
            message = 'The request could not be declined';
            break;
          case 'cancelled':
            message = 'The request could not be cancelled';
            break;
        }

        notification.error({ message, description });
      })
      .finally(mutate);
  };

  const typeLabel = TransferRequestTypeLabels[data?.request_type];
  const [senderType, receiverType] = TransferRequestAccountTypes[data?.request_type] || [];

  return (
    <>
      <PageHeader title="Request details" />
      <PageContent>
        <WrapperPanel title="Request details">
          <DetailRowWithLoading
            loading={isLoading}
            label="Status"
            data={<RequestStatusTag state={data?.state} />}
          />
          <DetailRowWithLoading
            loading={isLoading}
            label="Created on"
            data={moment(data?.created_at).format(dateTimeFormat)}
          />
          <DetailRowWithLoading loading={isLoading} label="Request type" data={typeLabel} />
          <DetailRowWithLoading
            loading={isLoading}
            label="From"
            data={
              <Row style={{ width: '100%', maxWidth: 1024 }}>
                <Col sm={12}>
                  {[data?.properties?.sender_first_name, data?.properties?.sender_last_name]
                    .filter(Boolean)
                    .join(' ')}
                </Col>
                <Col sm={12}>
                  {senderType}: {data?.properties.sender}
                </Col>
              </Row>
            }
          />
          <DetailRowWithLoading
            loading={isLoading}
            label="To"
            data={
              <Row style={{ width: '100%', maxWidth: 1024 }}>
                <Col sm={12}>
                  {[data?.properties?.receiver_first_name, data?.properties?.receiver_last_name]
                    .filter(Boolean)
                    .join(' ')}
                </Col>
                <Col sm={12}>
                  {receiverType}: {data?.properties.receiver}
                </Col>
              </Row>
            }
          />
          <DetailRowWithLoading
            loading={isLoading}
            label="Points to transfer"
            data={formatPoints(data?.amount)}
          />
          {data?.state !== 'new' && (
            <DetailRowWithLoading
              loading={isLoading}
              label="Last updated by"
              data={
                <Row style={{ width: '100%', maxWidth: 1024 }}>
                  <Col sm={24} md={8}>
                    {[data?.properties?.updated_by].filter(Boolean).join(' ')}
                  </Col>
                  <Col sm={24} md={8}>
                    {moment(data?.updated_at).format(dateTimeFormat)}
                  </Col>
                </Row>
              }
            />
          )}

          {['completed', 'declined', 'cancelled'].includes(data?.state) && (
            <DetailRowWithLoading loading={isLoading} label="Reason" data={data?.reason} />
          )}

          {data?.state === 'new' && (
            <Col xs={24} md={{ offset: 8, span: 16 }} style={{ display: 'flex', gap: 16 }}>
              <Button
                type="primary"
                size="large"
                disabled={disabledAction}
                onClick={setToInProgress}
                ghost
              >
                Change status to ‘In progress’
              </Button>
            </Col>
          )}
          {data?.state === 'in_progress' && (
            <Col xs={24} md={{ offset: 8, span: 16 }} style={{ display: 'flex', gap: 16 }}>
              <StyledButton
                type="primary"
                size="large"
                disabled={disabledAction}
                onClick={completeRequest}
                state="completed"
                style={{ width: 120 }}
              >
                Complete
              </StyledButton>
              <StyledButton
                type="primary"
                size="large"
                disabled={disabledAction}
                onClick={declineRequest}
                state="declined"
                style={{ width: 120 }}
              >
                Decline
              </StyledButton>
              <StyledButton
                type="primary"
                size="large"
                disabled={disabledAction}
                onClick={cancelRequest}
                state="cancelled"
                style={{ width: 120 }}
              >
                Cancel
              </StyledButton>
            </Col>
          )}
        </WrapperPanel>

        <BDOTransferRequestAuditLog id={requestId} state={data?.state} />
      </PageContent>
    </>
  );
};

export default BDOTransferRequest;
